<template>

  <div class="trips-open">
   <main-nav />
    <main-titlebar myclass="orangeBar"/>


    <div class="list-toolbar">
      <div class="" style="float:left;"  >
        <input type="text" v-model="search" class="form-input">
      </div>
      <div class="list-toolbar-button" style="float:right;"  @click="toggleListView()">
        <div v-if="curListView == 'c'"><i  class="fa-solid fa-border-all"></i></div>
        <div v-if="curListView == 'd'"><i  class="fa-solid fa-bars"></i></div>
      </div>
    </div>
    <!-- Tab content -->
    <div id="list" class="tabcontent" style="display:block;">
        <div class="trip-list-wrapper">
           <div v-for="trip in activeFiltered" :key="trip.id" @click="viewTrip(trip.id)"  >
          <!-- <div v-for="trip in trips" :key="trip.id" class="trip-row-wrapper" @click="viewTrip(trip.id)"  > -->
            <div class="trip-row" :class="{purpleBorder: trip.public == 1}">
              <div class="trip-col1 textAlignLeft"><i class="fa-solid fa-clock"></i> {{ formatDateTime(trip.etd) }}</div>
              <div class="trip-col1 textAlignRight"><i class="fa-solid fa-people-group"></i> {{ trip.passengers}}</div>
              <div class="trip-col2 textAlignLeft"><i class="fa-solid fa-crosshairs"></i> {{ trip.origin_address}} </div>
              <div class="trip-col2 textAlignLeft"><i class="fa-solid fa-crosshairs"></i> {{ trip.destination_address}}</div>
              <div class="trip-col1 textAlignLeft"><i class="fa-solid fa-hand-holding-dollar"></i> {{ trip.provider_name}}</div>
              <div class="trip-col1 textAlignRight"></div>
            </div>
          </div>
        </div>
    </div>

    <div id="map" class="tabcontent">
      <GMapMap
          :center="center"
          :zoom="10"
          map-type-id="terrain"
          style="width: 100%; height: 69vh"
      >
        <GMapMarker
            :key="marker.id"
            v-for="marker in markers"
            :position="marker.position"
        />
      </GMapMap>
    </div>
    <div class="tab">
      <button id="tab-list" class="tablinks active" @click="openTab( 'list')"><i class="fa-solid fa-list"></i> Lijst</button>
      <button id="tab-map" class="tablinks" @click="openTab( 'map')"><i class="fa-solid fa-map"></i> Kaart</button>
    </div>

  </div>

</template>

<script>
import {ref} from 'vue'
import TripService from "../../services/trip-service";

export default {
  name:'trips-open',
  data() {
    const search = ref('')
  var status = 'loading'
    var trips = [];
    var defaultCenter = {lat: 51.093048, lng: 6.842120};
    var center = {};
    var markers = [];
    var curListView = "d";
        var curGPSpos = this.currentLocation();
        console.log("curGPSpos: " + curGPSpos);

    if(curGPSpos == null) {
      center = defaultCenter;
    } else {
      center = curGPSpos;

    }
      console.log("center: " + center);
    return { trips,search, status, markers,center,curListView,curGPSpos }
  },
  methods: {
    viewTrip(id) {
      this.$router.push('/trips/view/' + id);
    },
    toggleListView() {
        console.log("toggleListView: " + this.curListView);
        if(this.curListView == "d") {
          this.curListView = "c";
        } else {
          this.curListView = "d";
        }

    }
  },
  computed: {
    activeFiltered() {
      return this.trips
        .filter(
          (entry) => this.trips.length
            ? Object.keys(this.trips[0])
                .some(key => ('' + entry[key]).toLowerCase().includes(this.search))
            : true
        );
    },
    curUserID() {
      // var tripOwner = this.trips[0]["owner"]
      var user  = this.$store.state.auth.user['userid'];
      return user;
    }
  },
  mounted() {
    var targ = this;
    TripService.getOpenTrips().then(
      (response) => {
        console.log("data loaded from: ");
        targ.status = response.status;
        if(response.data != null) {
          targ.trips = response.data;
        } else {
          targ.trips = [];

        }
        var minLat='';
        var  minLng = '';
        var  maxLat = '';
        var  maxLng = '';
        if(targ.trips != null) {
          for(var i=0 ; i<targ.trips.length ; i++ ) {
            var originCoords = targ.trips[i]["origin_location"].split(",");
            var curLat = parseFloat(originCoords[0]); 
            var curLng = parseFloat(originCoords[1]); 
            var origin_marker = {
              id: targ.trips[0]["origin_address"],
              position: {
                lat: curLat, 
                lng: curLng
              }
            };
            
            if(curLat < minLat || minLat == '') minLat = curLat; 
            if(curLat > maxLat) maxLat = curLat; 
            if(curLng < minLng || minLng == '') minLng = curLng; 
            if(curLng > maxLng) maxLng = curLng;           
            var centerLat = minLat + ((maxLat-minLat)/2)
            var centerLng = minLng + ((maxLng-minLng)/2)
            targ.center["lat"] = centerLat;
            targ.center["lng"] = centerLng;
            console.log("Center lat / lng: " + centerLat + " / " + centerLng);
            console.log("origin marker: " + JSON.stringify(origin_marker));
            this.markers.push(origin_marker);
          }
        }
      },
      (error) => {
        targ.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
  components: {
    'main-titlebar': require('@/components/titlebar.vue').default,
    'main-nav': require('@/components/mainmenu.vue').default,

  }
}
</script>